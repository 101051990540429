import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import imgStory from "../images/story.webp";

const OurStory = () => {
  return (
    <Box sx={{ backgroundColor: "#f9fafa" }}>
      <Container sx={{ py: 10 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              src={imgStory}
              sx={{ width: "100%", maxWidth: 945, mt: { xs: 4, md: 0 } }}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", md: "60%" } }}>
            <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
              Our story
            </Typography>
            <Typography component="p" variant="body">
              In 2016 our founder and CEO, Santosh Gumidelli and his wife, Sneh,
              opened a restaurant in Los Angeles, CA called Zafran Pot. The
              restaurant offered a unique style of Indian food that really
              resonated within and beyond the local Indian community. All of
              Santosh and Sneh’s friends came by the restaurant and those
              friends brought their friends. After five and a half years of
              running the restaurant, Santosh and Sneh sold Zafran Pot.
              <br />
              <br />
              As a former restaurateur, Santosh has a deep empathy for
              restaurants, whether it’s increasing foot and digital traffic,
              navigating rising food and overhead costs, or maintaining the
              highest food quality. After selling Zafran Pot, Santosh is back in
              the restaurant business - except not in the way he first arrived.
              <br />
              <br />
              Enter MeridioAI - conversational artificial intelligence that can
              autonomously process customer orders and payments over the phone.
              MeridioAI wants to use its AI capabilities to empower restaurants
              to drive sales across all omni channels, build tremendous customer
              loyalty, and create a best-in-class customer service experience.
              Restaurants need all the help they can get to grow their business
              - and we want to help you get there.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OurStory;
