import { Container, Box, Typography, Link } from "@mui/material";
import React from "react";
import { navItems } from "../data/navItems";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "primary.light", pt: 10, pb: 15 }}>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box
            component="img"
            src="/images/logo-footer.svg"
            alt="footer logo"
            sx={{
              width: "100%",
              maxWidth: { xs: 120, lg: 120 },
              height: "auto",
            }}
          />
          <Typography variant="bodySmall" component="p">
            Customer-Centric Solutions, AI Powered Conversations
          </Typography>
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mb: 4,
            }}
          >
            <Link
              href="/"
              underline="hover"
              color="primary.main"
              sx={{ my: 1.5, display: "inline-block", mx: 2 }}
            >
              <Typography
                variant="body"
                color="primary.main"
                sx={{ fontWeight: 600 }}
              >
                Home
              </Typography>
            </Link>
            {navItems.map((navItem, i) => (
              <Link
                key={i}
                href={navItem.url}
                underline="hover"
                color="primary.main"
                sx={{ my: 1.5, display: "inline-block", mx: 2 }}
              >
                <Typography
                  variant="body"
                  color="primary.main"
                  sx={{ fontWeight: 600 }}
                >
                  {navItem.title}
                </Typography>
              </Link>
            ))}
            <Link
              href="/contact"
              underline="hover"
              color="primary.main"
              sx={{ my: 1.5, display: "inline-block", mx: 2 }}
            >
              <Typography
                variant="body"
                color="primary.main"
                sx={{ fontWeight: 600 }}
              >
                Contact
              </Typography>
            </Link>
          </Box>
        </Box>

        <Box>
          <Typography
            variant="body"
            component="p"
            color="primary.main"
            sx={{ fontSize: "0.875rem", mb: 2, textAlign: "center" }}
          >
            ©MeridioAI
            <br />
            All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
