import { createTheme } from "@mui/material";

const MeridioTheme = createTheme({
  /******************************************
   * TYPOGRAPHY
   *****************************************/
  typography: {
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#605D5D",
    body: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#605D5D",
    },
    bodyWhite: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#ffffff",
    },
    bodySmall: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.25,
      color: "#605D5D",
    },
    bodyLight: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 300,
      lineHeight: 1.2,
      color: "#605D5D",
    },
    bodySemi: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#605D5D",
    },
    bodyBold: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: 1.5,
      color: "#605D5D",
    },
    navLink: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1,
      color: "#231F20",
    },
    subNavLink: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1,
    },
    formLabel: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 1,
      color: "#333333",
    },
    heroHeadline: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: 1.5,
      color: "#75AD44",
      marginBottom: "20px",
      textTransform: "uppercase",
    },
    bodyLink: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#75AD44",
    },
    h1: {
      fontFamily: "Roboto",
      fontSize: "3rem",
      lineHeight: 1.125,
      fontWeight: 700,
      color: "#333333",
      "@media (max-width:900px)": {
        fontSize: "3rem",
      },
    },
    h2: {
      fontFamily: "Roboto",
      fontSize: "4rem",
      lineHeight: 1.125,
      fontWeight: 700,
      color: "#333333",
      "@media (max-width:900px)": {
        fontSize: "2.25rem",
      },
    },
    h3: {
      fontFamily: "Roboto",
      fontSize: "3rem",
      lineHeight: 1.125,
      fontWeight: 700,
      color: "#333333",
      "@media (max-width:900px)": {
        fontSize: "1.75rem",
      },
    },
    h4: {
      fontFamily: "Roboto",
      fontSize: "2rem",
      lineHeight: 1.125,
      fontWeight: 500,
      color: "#333333",
      "@media (max-width:900px)": {
        fontSize: "1.5rem",
      },
    },
    h5: {
      fontFamily: "Roboto",
      fontSize: "1.25rem",
      lineHeight: 1,
      fontWeight: 600,
      color: "#333333",
    },
    h6: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      lineHeight: 1,
      fontWeight: 600,
      color: "#333333",
    },
  },
  /******************************************
   * COLORS
   *****************************************/
  palette: {
    primary: {
      main: "#333333",
      light: "#cccccc",
      dark: "#417791",
      white: "#ffffff",
    },
    secondary: {
      main: "#75AD44",
    },
    success: {
      main: "#22C55E",
      light: "#86EFAC",
    },
    neutral: {
      grey: "#CCCCCC",
      dark: "#333333",
    },
    warning: {
      main: "#FFE15F",
      light: "#FFF4C6",
      dark: "#027E6C",
    },
    error: {
      main: "#BB4038",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          backgroundColor: "white",
          padding: "12px 16px",
          fontSize: "1rem",
          color: "#173162",
          height: "48px",
          textTransform: "none",
        },
        outlined: {
          backgroundColor: "white",
          padding: "12px 16px",
          borderRadius: 60,
          color: "#559B3C",
          border: "2px solid #559B3C",
          fontSize: "1rem",
          fontWeight: 400,
          textTransform: "none",
          lineHeight: 1,
          height: "48px",
          "&:hover": {
            border: "2px solid #559B3C",
            backgroundColor: "#f1f5fc",
          },
        },
        contained: {
          background: "linear-gradient(180deg, #FF6825 0%, #DE340D 100%)",
          backgroundColor: "#DE340D",
          display: "flex",
          alignItems: "center",
          border: 0,
          fontFamily: "Roboto",
          fontSize: "1.125rem",
          fontWeight: 400,
          textTransform: "none",
          padding: "10px 30px",
          transition: "none",
          transitionDelay: 0,
          "&:hover": {
            background: "#DE340D",
          },
          "&:active": {
            background: "#506CA7",
          },
          "@media (max-width:900px)": {
            height: "48px",
          },
        },
      },
    },
  },
});

export default MeridioTheme;
